var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Position"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codePosition) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-tabs', [_c('b-tab', {
    attrs: {
      "title": "Top"
    }
  }, [_c('b-tabs', {
    attrs: {
      "content-class": "mt-2"
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "Tab 1"
    }
  }, [_c('b-card-text', [_vm._v(" Oat cake marzipan cake lollipop caramels wafer pie jelly beans. Icing halvah chocolate cake carrot cake. Jelly beans carrot cake marshmallow gingerbread chocolate cake. Sweet fruitcake cheesecake biscuit cotton candy. Cookie powder marshmallow donut. Gummies cupcake croissant. ")])], 1), _c('b-tab', {
    attrs: {
      "active": "",
      "title": "Tab 2"
    }
  }, [_c('b-card-text', [_vm._v(" Sugar plum tootsie roll biscuit caramels. Liquorice brownie pastry cotton candy oat cake fruitcake jelly chupa chups. Sweet fruitcake cheesecake biscuit cotton candy. Cookie powder marshmallow donut. Pudding caramels pastry powder cake soufflé wafer caramels. Jelly-o pie cupcake. ")])], 1), _c('b-tab', {
    attrs: {
      "title": "Tab 2"
    }
  }, [_c('b-card-text', [_vm._v(" Biscuit ice cream halvah candy canes bear claw ice cream cake chocolate bar donut. Toffee cotton candy liquorice. Oat cake lemon drops gingerbread dessert caramels. Sweet dessert jujubes powder sweet sesame snaps. Sweet fruitcake cheesecake biscuit cotton candy. Cookie powder marshmallow donut. ")])], 1)], 1)], 1), _c('b-tab', {
    attrs: {
      "title": "Right"
    }
  }, [_c('b-tabs', {
    attrs: {
      "vertical": "",
      "end": "",
      "nav-class": "nav-right",
      "nav-wrapper-class": "nav-vertical"
    }
  }, [_c('b-tab', {
    attrs: {
      "active": "",
      "title": "Tab 1"
    }
  }, [_c('b-card-text', [_vm._v(" Oat cake marzipan cake lollipop caramels wafer pie jelly beans. Icing halvah chocolate cake carrot cake. Jelly beans carrot cake marshmallow gingerbread chocolate cake. Sweet fruitcake cheesecake biscuit cotton candy. Cookie powder marshmallow donut. Gummies cupcake croissant. ")]), _c('b-card-text', [_vm._v(" Sweet chocolate muffin fruitcake gummies jujubes pie lollipop. Brownie marshmallow caramels gingerbread jelly beans chocolate bar oat cake wafer. ")])], 1), _c('b-tab', {
    attrs: {
      "title": "Tab 2"
    }
  }, [_c('b-card-text', [_vm._v(" Sugar plum tootsie roll biscuit caramels. Sweet fruitcake cheesecake biscuit cotton candy. Cookie powder marshmallow donut. Liquorice brownie pastry cotton candy oat cake fruitcake jelly chupa chups. Pudding caramels pastry powder cake soufflé wafer caramels. Jelly-o pie cupcake. ")]), _c('b-card-text', [_vm._v(" Sweet chocolate muffin fruitcake gummies jujubes pie lollipop. Brownie marshmallow caramels gingerbread jelly beans chocolate bar oat cake wafer. ")])], 1), _c('b-tab', {
    attrs: {
      "title": "Tab 3"
    }
  }, [_c('b-card-text', [_vm._v(" Sweet fruitcake cheesecake biscuit cotton candy. Cookie powder marshmallow donut. Biscuit ice cream halvah candy canes bear claw ice cream cake chocolate bar donut. Toffee cotton candy liquorice. Oat cake lemon drops gingerbread dessert caramels. Sweet dessert jujubes powder sweet sesame snaps. ")]), _c('b-card-text', [_vm._v(" Candy cookie sweet roll bear claw sweet roll. Cake tiramisu cotton candy gingerbread cheesecake toffee cake. Cookie liquorice dessert candy canes jelly. ")])], 1)], 1)], 1), _c('b-tab', {
    attrs: {
      "title": "Bottom"
    }
  }, [_c('b-tabs', {
    attrs: {
      "end": ""
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "Tab 1",
      "active": ""
    }
  }, [_c('b-card-text', [_vm._v(" Pudding candy canes sugar plum cookie chocolate cake powder croissant. Carrot cake tiramisu danish candy cake muffin croissant tart dessert. ")]), _c('b-card-text', [_vm._v(" Tiramisu caramels candy canes chocolate cake sweet roll liquorice icing cupcake. ")])], 1), _c('b-tab', {
    attrs: {
      "title": "Tab 2"
    }
  }, [_c('b-card-text', [_vm._v(" Candy canes donut chupa chups candy canes lemon drops oat cake wafer. Cotton candy candy canes marzipan carrot cake. Chocolate bar danish icing sweet apple pie jelly-o carrot cake cookie cake. ")]), _c('b-card-text', [_vm._v(" Sesame snaps lemon drops candy marzipan donut brownie tootsie roll. Icing croissant bonbon biscuit gummi bears. ")])], 1), _c('b-tab', {
    attrs: {
      "title": "Tab 3"
    }
  }, [_c('b-card-text', [_vm._v(" Carrot cake dragée chocolate. Lemon drops ice cream wafer gummies dragée. Chocolate bar liquorice cheesecake cookie chupa chups marshmallow oat cake biscuit. ")]), _c('b-card-text', [_vm._v(" Dessert toffee fruitcake ice cream powder tootsie roll cake. ")])], 1)], 1)], 1), _c('b-tab', {
    attrs: {
      "active": "",
      "title": "Left"
    }
  }, [_c('b-tabs', {
    attrs: {
      "vertical": "",
      "nav-wrapper-class": "nav-vertical"
    }
  }, [_c('b-tab', {
    attrs: {
      "active": "",
      "title": "Tab 1"
    }
  }, [_c('b-card-text', [_vm._v(" Oat cake marzipan cake lollipop caramels wafer pie jelly beans. Icing halvah chocolate cake carrot cake. Jelly beans carrot cake marshmallow gingerbread chocolate cake. Sweet fruitcake cheesecake biscuit cotton candy. Cookie powder marshmallow donut. Gummies cupcake croissant. ")]), _c('b-card-text', [_vm._v(" Sweet chocolate muffin fruitcake gummies jujubes pie lollipop. Brownie marshmallow caramels gingerbread jelly beans chocolate bar oat cake wafer. ")])], 1), _c('b-tab', {
    attrs: {
      "title": "Tab 2"
    }
  }, [_c('b-card-text', [_vm._v(" Sugar plum tootsie roll biscuit caramels. Sweet fruitcake cheesecake biscuit cotton candy. Cookie powder marshmallow donut. Liquorice brownie pastry cotton candy oat cake fruitcake jelly chupa chups. Pudding caramels pastry powder cake soufflé wafer caramels. Jelly-o pie cupcake. ")]), _c('b-card-text', [_vm._v(" Chocolate bar danish icing sweet apple pie jelly-o carrot cake cookie cake. ")])], 1), _c('b-tab', {
    attrs: {
      "title": "Tab 3"
    }
  }, [_c('b-card-text', [_vm._v(" Sweet fruitcake cheesecake biscuit cotton candy. Cookie powder marshmallow donut. Biscuit ice cream halvah candy canes bear claw ice cream cake chocolate bar donut. Toffee cotton candy liquorice. Oat cake lemon drops gingerbread dessert caramels. Sweet dessert jujubes powder sweet sesame snaps. ")]), _c('b-card-text', [_vm._v(" Candy cookie sweet roll bear claw sweet roll. Cake tiramisu cotton candy gingerbread cheesecake toffee cake. Cookie liquorice dessert candy canes jelly. ")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }