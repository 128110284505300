var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Prigrammatic activation"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeProgrammatically) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-tabs', {
    model: {
      value: _vm.tabIndex,
      callback: function callback($$v) {
        _vm.tabIndex = $$v;
      },
      expression: "tabIndex"
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "General"
    }
  }, [_c('b-card-text', [_vm._v(" Cookie cheesecake gummi bears. Topping apple pie chupa chups topping soufflé chocolate bar sesame snaps fruitcake sweet. Danish donut brownie wafer. Jelly beans apple pie wafer danish pastry lollipop biscuit marshmallow ")]), _c('b-card-text', [_vm._v(" Pie powder sweet cotton candy topping pie. Chocolate gummi bears tootsie roll gingerbread. Jujubes pie bonbon chocolate cake dragée macaroon candy. ")])], 1), _c('b-tab', {
    attrs: {
      "title": "Edit profile"
    }
  }, [_c('b-card-text', [_vm._v("I'm the second tab")]), _c('b-card', {
    staticClass: "border mb-0"
  }, [_vm._v(" Apple pie jelly sugar plum cupcake biscuit danish cheesecake danish pastry. Cheesecake lemon pastry marzipan. Wafer bear claw chocolate bar soufflé gummies sugar plum macaroon brownie. ")])], 1), _c('b-tab', {
    attrs: {
      "title": "Premium Plan",
      "disabled": ""
    }
  }, [_vm._v(" Sibzamini! ")]), _c('b-tab', {
    attrs: {
      "title": "Info"
    }
  }, [_c('b-card-text', [_vm._v(" I'm the last tab ")]), _c('b-card-text', [_vm._v(" Lemon drops icing chocolate bar icing cheesecake sesame snaps tootsie roll. Bonbon carrot cake biscuit. Apple pie topping jujubes candy gingerbread soufflé chocolate bar. Jelly lollipop candy canes. Lemon drops marshmallow pastry. Carrot cake lollipop cookie cotton candy dragée liquorice. ")])], 1)], 1), _c('div', {
    staticClass: "text-center"
  }, [_c('b-button-group', {
    staticClass: "mt-1"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function click($event) {
        _vm.tabIndex--;
      }
    }
  }, [_vm._v(" Previous ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function click($event) {
        _vm.tabIndex++;
      }
    }
  }, [_vm._v(" Next ")])], 1), _c('b-card-text', [_c('small', [_vm._v("Current Tab: " + _vm._s(_vm.tabIndex))])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }