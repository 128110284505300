var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Alignments"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeAlignment) + " ")];
      },
      proxy: true
    }])
  }, [_c('h6', [_vm._v("Left")]), _c('div', {
    staticClass: "mb-3"
  }, [_c('b-tabs', {
    attrs: {
      "align": "left"
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "Home"
    }
  }), _c('b-tab', {
    attrs: {
      "active": "",
      "title": "Service"
    }
  }), _c('b-tab', {
    attrs: {
      "title": "Account"
    }
  })], 1)], 1), _c('h6', [_vm._v(" Center ")]), _c('div', {
    staticClass: "mb-3"
  }, [_c('b-tabs', {
    attrs: {
      "align": "center"
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "Home",
      "active": ""
    }
  }), _c('b-tab', {
    attrs: {
      "title": "Service"
    }
  }), _c('b-tab', {
    attrs: {
      "title": "Account"
    }
  })], 1)], 1), _c('h5', [_vm._v("Right")]), _c('b-tabs', {
    attrs: {
      "align": "right"
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "Home"
    }
  }), _c('b-tab', {
    attrs: {
      "title": "Service"
    }
  }), _c('b-tab', {
    attrs: {
      "active": "",
      "title": "Account"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }